.home__banner {
	background-color: #333333;
	text-align: center;
	.home__bannerText {
		color: white;
		padding: 17px;
		font-size: 14px;
	}
}

.home__navigation {
	display: flex;
	align-items: center;
	justify-content: center;
	padding-top: 9px;
}

.homeNavItem {
    text-align: center;
    padding: 10px 23px;
    .homeNavItem__Icon {
      width: 30px;
      object-fit: contain;
      margin-bottom: 2px;
    }
    .homeNavItem__text {
      margin: 0px !important;
      font-size: 10px;
      text-align: center;
    }
  }
  

.home__slider {
	// background-image: url(../../assets/img/homepageImage.jpg);
	// background-size: contain;
	// position: relative;
	// background-position: center;
	// background-repeat: no-repeat;
  position: relative;
}

.home__sliderText {
	font-size: 25px;
}


.home__sliderButton {
	background-color: black;
	color: white;
	border: black;
	padding: 8px 12px;
	margin-top: 20px;
	border-radius: 20px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 145px;
}

.chev-right {
	width: 13px;
}

.home__recommendedProductsContainer {
	background-color: #f5f5f5;
	padding: 50px 20px;
	p {
		font-size: 26px;
		text-align: center;
		font-weight: bold;
	}

	.home__recommendedProductsSlider {
		display: flex;
		align-items: center;
		overflow-x: scroll;
		padding: 1px;
	}
	::-webkit-scrollbar {
		display: none;
	}
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */
}

@media only screen and (min-width: 1000px) {
	.home__sliderImg {
		position: relative;
		left: 70%;
		/* right: -90px; */
		width: 80%;
		top: 0px;
		width: 31%;
	}
}
