.dbc_container {
  background-color: white;
  .dbc__header {
    margin-top: 26px;
    color: #1c1c1c;
    font-weight: bold;
    font-size: 25px;
    margin-bottom: 4px;
  }

  p {
    font-weight: 500;
  }

  // input {
  //   margin-top: 22px;
  //   width: 330px;
  //   border: none;
  //   outline: none;
  //   background-color: rgb(255, 255, 255);
  //   padding: 17px;
  //   border-radius: 12px;
  // }

  .dbc_search_box {
    width: 100%;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: start;
    padding: 11px 25px;
    margin-top: 27px;
    background: #f2f2f2;
    font-size: 18px;
    margin-bottom: 50px;
  }

  .dbc_search_input {
    width: 100%;
    outline: none;
    border: none;
    font-size: 18px;
    background-color: #ffcc0000;
    padding: 0px;
    margin-left: 4px;
    margin-top: 0px !important;
  }

  .dbc_search_icon {
    font-size: 22px;
    margin: 4px;
    padding: 6px 15px;
  }

  .dbc_ranges_input {
    display: flex;
    flex-direction: column;
    margin-top: 15px;

    .dbc_ranges_container{
      margin-top: 30px;
    }

    // input[type="range"] {
    //   -webkit-appearance: none;
    //   height: 11px;
    //   background: #c8cbcb;
    //   border-radius: 5px;
    //   margin-top: 30px;
    // }

    // input[type="range"]::-webkit-slider-thumb {
    //   -webkit-appearance: none;
    //   height: 26px;
    //   width: 26px;
    //   border-radius: 50%;
    //   border: 2px solid white;
    //   background: #ffcc00;
    //   cursor: ew-resize;
    //   box-shadow: 0 0 2px 0 #555;
    //   transition: background 0.3s ease-in-out;
    //   box-shadow: 0px 2px 5px 0px rgb(130, 130, 130);
    // }

    // input[type="range"]::-webkit-slider-runnable-track {
    //   // -webkit-appearance: none;
    //   box-shadow: none;
    //   border: none;
    //   background: transparent;
    // }

    small {
      margin-bottom: 19px;
      font-weight: bold;
      font-size: 11px;
    }
  }
}

.dbc_buttom {
  button {
    width: 88%;
    margin-top: 15px;
    padding: 14px;
    border-radius: 26px;
    border: none;
    background-color: #ffcc00;
    font-size: 16px;
    margin-bottom: 30px;
    background-color: white;
  }
}
