.recommendedProductsCard {
    padding: 9px;
    margin-top: 2px;
    margin-right: 20px;
    border-radius: 20px;
    text-align: left;
	.product_image {
		object-fit: cover;
		border-radius: 20px;
	}

	.recommendedProductsCard_title {
		font-size: 13px;
		font-weight: bold;
		text-align: left;
	}

	.recommendedProductsCard_price {
		font-size: 23px;
		text-align: start;
		margin-left: 4px;
		margin-top: 2px;
	}

	.recommendedProductsCard_discount {
		p {
			font-size: 13px;
			text-align: start;
			margin-left: 6px;
			margin-top: 2px;
			font-weight: 100;
		}
	}
	small {
		margin-right: -3px;

	}
}

.grey-bg {
	background-color: #f2f2f2;
}

.white-bg {
	background-color: white;
}

.stars_number {
	color: #9f9f9f;
}

.recommendedProductsCard_saveItem {
	img {
		object-fit: contain;
	}
}

.recommendedProductsCard_cardBottom {
	justify-content: space-between;
}
