$card-border-radius: 37px;

.dynamicB__container {
  padding: 40px 28px;

  .dynamicB__header {
    margin-top: 32px;
    color: #1c1c1c;
    font-weight: bold;
  }

  .card {
    background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
      url(../../assets/img/SMEProductImg.jpg);
    padding-top: 200px;
    border-radius: $card-border-radius;
    margin-top: 30px;
    background-size: contain;
    position: relative;
    background-repeat: no-repeat;
    box-shadow: 2px 15px 27px 2px lightgrey;
    .cardTag {
      position: absolute;
      top: 21px;
      right: 30px;
      background: #ffcc00;
      padding: 3px 15px;
      font-weight: bold;
      font-size: 15px;
    }
  }

  .cardInner {
    background-color: white;
    border-radius: $card-border-radius;
    padding: 20px;
    p {
      font-size: 34px;
      font-weight: bold;
    }

    .cardHeader {
      justify-content: space-between;
      p {
        font-size: 29px !important;
      }
    }

    .cardDropdown {
      margin-top: 9px;
      padding: 8px;
      select {
        width: 100%;
        border: 1px solid black;
        padding: 8px 15px;
        font-size: 18px;
        font-weight: 700;
        outline: none;
        border-radius: 11px;
      }
    }

    .aboutThisItem {
      background-color: #f5f5f5;
      border-radius: $card-border-radius;
      margin-top: 18px;
      padding: 17px;
      p {
        font-size: 1.1rem;
        font-weight: 600;
        margin-bottom: 6px;
      }
      table {
        width: 100%;
      }
      td {
        color: #6c6b6b;
        padding: 6px 0px;
      }
    }
  }

  .cardButtons_container {
    .buynow {
      width: 100%;
      margin-top: 30px;
      border: none;
      font-size: 18px;
      font-weight: bold;
      border-radius: 26px;
      padding: 12px;
      background-color: #ffca05;
      border: 1px solid #ffca05;
    }
    .addtocart {
      width: 100%;
      margin-top: 20px;
      border: none;
      font-size: 18px;
      font-weight: bold;
      border-radius: 26px;
      padding: 12px;
      background-color: #ffffff;
      border: 1px solid black;
    }
  }

  .cardButtons_description {
    padding-bottom: 28px;
    p {
      font-size: 1.1rem;
      font-weight: 600;
      margin-top: 30px;
    }
    .desc_text {
      font-weight: 500;
      font-size: 16px;
      margin-top: 12px !important;
      color: #585757;
    }
  }
}
