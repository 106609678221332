* {
	margin: 0;
}

.loader_container {
	display: grid;
	place-items: center;
	height: 100vh;
}

.loader {
	border: 0.1px solid #f3f3f3;
	border-radius: 50%;
	border-top: 0.1px solid #ffcc38;
	border-bottom: 0.1px solid #ffcc38;
	width: 120px;
	height: 120px;
	-webkit-animation: spin 2s linear infinite;
	animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
	0% {
		-webkit-transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
	}
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

.productslider_container.with-bg-grey {
	background-color: #f2f2f2;
}
.productslider_container {
	background-color: white;
	padding: 70px 20px;
	p {
		font-size: 18px;
		font-weight: bold;
	}

	.productslider {
		display: flex;
		align-items: center;
		overflow-x: scroll;
		padding: 19px 26px;
	}
	
}

.custom-scrollbars::-webkit-scrollbar {
	width: 7px;
	height: 7px;
	display: block;
}
 
.custom-scrollbars::-webkit-scrollbar-track {
	background-color: #e4e4e4;
	border-radius: 100px;
}
 
.custom-scrollbars::-webkit-scrollbar-thumb {
	background-color: gray;
	border-radius: 100px;
}

.file-preview {
	display: inline-block;
	position: relative;
	width: 60px;
	height: 63px;
	margin-right: 10px;
	border-radius: 5px;
	background-size: cover;
	background-repeat: no-repeat;
	border: solid rgb(184, 184, 184) 1px;
	background-color: #eeeeee;
	background-position: center;
}

.file-preview .close-button {
	position: absolute !important;
	right: -5px;
	top: -8px;
	cursor: pointer;
}

.file-preview .loader2 {
	width: 20px;
	height: 20px;
	margin: 20px auto;
	background-color: #ff7d78;
}

.file-preview.add {
	border: solid #f8f8f8 1px;
}

.file-preview.add svg {
	display: block;
	margin: 20px auto;
	width: 22px;
	height: 22px;
}

.file-preview.camera {
	border: solid #f8f8f8 1px;
	background-color: #b7e6fc !important;
	margin-right: 5px;
	cursor: pointer;
}

.file-preview.camera img {
	display: block;
	margin: 20px auto;
	width: 32px;
	height: 32px;
}

.capture-modal {
	background-color: rgba(0, 0, 0, 0.589);
	padding-top: 0;
	padding-bottom: 0;
}

.capture-modal .swal-content {
	padding: 10px;
}

.swal-button {
	font-weight: 300;
	border: none;
}

.capture-modal .swal-button--confirm:hover {
	color: #000 !important;
	background-color: #ecb829 !important;
}

.capture-modal .swal-button--cancel {
	background-color: #fff;
	color: #000;
}

.capture-modal .swal-button--cancel:hover {
	background-color: #f1f1f1 !important;
}

.capture-modal .swal-footer {
	background-color: rgba(0, 0, 0, 0.589);
}

.loader2 {
	width: 48px;
	height: 48px;
	border-radius: 50%;
	position: relative;
	animation: rotate 1s linear infinite;
}

.loader2::before,
.loader2::after {
	content: '';
	box-sizing: border-box;
	position: absolute;
	inset: 0px;
	border-radius: 50%;
	border: 5px solid #fff;
	animation: prixClipFix 2s linear infinite;
}

.loader2::after {
	transform: rotate3d(90, 90, 0, 180deg);
	border-color: #ff3d00;
}

.dot {
	width: 6px;
	height: 6px;
	background: currentColor;
	display: inline-block;
	border-radius: 50%;
	right: 0px;
	bottom: 0px;
	margin: 0px 2.5px;
	position: relative;
}

.dots-cont > .dot {
	position: relative;
	bottom: 0px;
	animation-name: jump;
	animation-duration: 0.3s;
	animation-iteration-count: infinite;
	animation-direction: alternate;
	animation-timing-function: ease;
}

.dots-cont .dot-1 {
	-webkit-animation-delay: 100ms;
	animation-delay: 100ms;
}

.dots-cont .dot-2 {
	-webkit-animation-delay: 200ms;
	animation-delay: 200ms;
}

.dots-cont .dot-3 {
	-webkit-animation-delay: 300ms;
	animation-delay: 300ms;
}


.map_results_container {
	padding: 25px 0px;
	padding-bottom: 0px;
	div {
	  padding: 9px 36px;
	  border-bottom: 0.5px solid #DBDBDB;
	  border-top: 0.5px solid #DBDBDB;
	  font-size: 12px;
	  font-weight: bold;  }
	div::before {
	  content: url(./assets/icons/locationPinGray.png);
	  margin-right: 13px;
	}
	.useMyLocation::before {
	  content: url(./assets/icons/locatoinPinYellow.png) !important;
	}
	.useMyLocation{
	  color: #00678F;
	}
  }

@keyframes jump {
	from {
		bottom: 0px;
	}
	to {
		bottom: 7px;
	}
}

@-webkit-keyframes jump {
	from {
		bottom: 0px;
	}

	to {
		bottom: 10px;
	}
}

.shorten-text {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
  }
