.mt-10{margin-top: 10px;}
.login_container {
  padding: 41px 20px;
  min-height: 500px;
}

.login-modal-body {
  padding: 25px;
  border-radius: 20px;
  background-color: white;
  box-shadow: 2px 15px 27px 2px lightgrey;

  .title {
    font-size: 24px;
    margin-bottom: 20px;
    text-align: center;
  }

  .phone-number-input-container {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    background-color: #f2f2f2;
    padding: 10px;
    border-radius: 10px;
  }

  .phone-number-input-icon {
    margin-right: 3px;
  }

  .phone-number-input {
    outline: none;
    border: none;
    background-color: transparent;
    font-size: 14px;
  }
  .phone-number-input::placeholder {
    font-weight: bold;
  }

  .row {
    display: flex;
    flex-direction: row;
  }

  .wFull {
    width: 80%;
  }

  .mb {
    margin-bottom: 5px;
    &-6 {
      margin-bottom: 6px;
    }
  }

  .forgot-password-link {
    color: #c4c4c4;
    font-weight: bold;
    font-size: 12px;
  }

  .align-center {
    align-items: center;
  }

  .remember-me-text {
    color: black;
    font-weight: bold;
    font-size: 12px;
  }

  .remember-me-checkbox {
    margin-right: 2px;
  }

  .login-button {
    cursor: pointer;
    width: 100%;
    max-width: 500px;
    margin-top: 20px;
    border: none;
    font-size: 14px;
    font-weight: bold;
    border-radius: 26px;
    padding: 12px;
    background-color: #ffca05;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover{
      background-color: #ffc90571;
    }
  }

  .login-socials-link {
    margin-top: 14px;
    font-size: 12px;
    font-weight: bold;
    color: black;
  }
  .justify-center {
    display: flex;
    justify-content: center;
  }

  .social-links {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 15px;
  }

  .social-link-container {
    margin-left: 7px;
    margin-bottom: 10px;
    width: 75px;
    height: 38px;
    border-radius: 38px;
    background-color: #ffca05;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .horizontal-line {
    height: 0.04px;
    background-color: #c4c4c4;
  }

  .sign-up-text {
    font-weight: bold;
    color: black;
    font-size: 12px;
    margin-left: 5px;
    text-align: center;
  }

  .text-grey {
    color: #c4c4c4;
    font-size: 12px;
  }

  .mt {
    margin-top: 5px;
    &-6 {
      margin-top: 6px;
    }
  }

  .social-icon {
    width: 20px;
    height: 20px;
  }
}
