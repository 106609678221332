.location_search_box {
    width: 95%;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: start;
    padding: 4px 2px;
    margin-bottom: 5px;
  }

  .location_search_input {
    width: 100%;
    border: none;
    outline: none;
    font-size: 16px;
    background-color: #ffcc0000;
  }

  .location_search_icon {
    font-size: 22px;
    margin: 4px;
    padding: 6px 15px;
  }

  .pac-container {
    font-family: MTNBrighterSans;
   }
   .pac-item,
   .pac-item-query {
   }
   .pac-item:hover {
   }
   .pac-container:after{display:none !important;}