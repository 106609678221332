$card-border-radius: 37px;

.header {
  background-color: #ffca05;
  margin-top: -150px;
  width: 100%;
}
.done-button {
  cursor: pointer;
  width: 100%;
  max-width: 500px;
  margin-top: 30px;
  border: none;
  font-size: 18px;
  font-weight: bold;
  border-radius: 26px;
  padding: 12px;
  background-color: #ffca05;
  border: 1px solid #ffca05;

}

.download-success-button {
  cursor: pointer;
  width: 100%;
  max-width: 500px;
  margin-top: 15px;
  border: none;
  font-size: 10px;
  font-weight: bold;
  border-radius: 26px;
  padding: 12px;
  background-color: #ffca05;
  border: 1px solid #ffca05;
}

.outline-success-button {
  cursor: pointer;
  width: 100%;
  max-width: 500px;
  margin-top: 10px;
  border: none;
  font-size: 10px;
  font-weight: bold;
  border-radius: 26px;
  padding: 12px;
  color: black;
  background-color: white;
  border: 1px solid black;
}

.text-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  word-wrap: normal;
  justify-content: center;
}

.text {
  color: #585757;
}

.text-bold {
  font-weight: bold;
}

.margin-horizontal {
  margin-left: 5px;
  margin-right: 5px;

  &-10 {
    margin-left: 10px;
    margin-right: 10px;
  }
}

.margin-bottom { 
  margin-bottom: 5px;

  &-10 {
    margin-bottom: 10px;
  }
}

.modal-success-description-text {
  width: 200px;
  font-size: 9px;
}

.margin-right {
  margin-right: 5px;

  &-10 {
    margin-right: 10px;
  }
}

.order-number-section {
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #F2F2F2;
}

.order-number-container {
  max-width: 500px;
  display: flex;
  justify-content: start;
  align-items: center;
  height: 100%;
}


.container {
  padding: 40px 28px;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
 
}
.bg-black{
  background-color: black;
}

.modal-success-body {
  background-color: white;
  padding-top: 30px;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 20px;
  width: 100%;
  border-radius: 20px;
  max-width: 500px;
}
.align-center {
  align-items: center;
}

.no-margin {
  margin: 0px;
}

.my {
  position: relative;
  top: 3.5px;
  font-size: 12px;

}

.my-mtn-logo {
  background-color: #ffca05;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 8px;
  width: 50px;
  // padding:5.5px;
  padding-bottom: 12px;
}


.modal-success-title {
  font-weight: bold;
  font-size: 17px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.center-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.modal.id-notice .yellow-btn {
  font-size: 12px;
}


.modal-success {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 150;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}


.modal .yellow-btn {
  text-align: center;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: bold;
  margin-top: 7px;
}
