.cartCard__container {
    .cartCard__Details {
      background-color: white;
      border-radius: 30px;
      padding-top: 15px;
      margin-bottom: 24px;
      .cardCard__Inner {
        padding: 28px;
        padding-top: 0px;
        padding-bottom: 0px;
      }
      img {
        max-width: 122px;
        width: 100px;
      }
      .cartCard__Details_texts {
        padding-left: 20px;
      }
      .cartCard__Details__brand {
        color: #898989;
        text-transform: uppercase;
        font-size: 12px;
        margin: 0px;
      }
      .cartCard__Details__name {
        font-size: 13px;
        font-weight: 100;
        margin: 0px;
      }
      .cartCard__Details__price {
        font-size: 24px;
        font-weight: bold;
        margin: 0px;
      }
      .cartCard__Details__discount {
        font-size: 14px;
        font-weight: 100;
        margin: 0px;
      }
      .cartCard__Buttons{
        width: 100%;
        text-align: center;
        margin-top: 26px;
        font-size: 11.3px;
      }
      .cartCard__Buttons_left{
        flex: 0.5;
        padding: 16px;
        padding-left: 30px;
        border-top: 1px solid #F2F2F2;
        border-right: 1px solid #F2F2F2;
      }
      .cartCard__Buttons_right{
        flex: 0.5;
        border-top: 1px solid #F2F2F2;
        padding: 16px;
        border-top: 1px solid #F2F2F2;
      }
      .cartCard__Buttons_right:hover{
          background-color: rgba(211, 211, 211, 0.393);
          border-bottom-right-radius: 30px;
      }
      .cartCard__Buttons_left:hover{
        background-color: rgba(211, 211, 211, 0.393);
        border-bottom-left-radius: 30px;
    }
    }
  }