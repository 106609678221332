.margin-bottom-30 {
	margin-bottom: 30px;
}

.fulfillment__details__section_title {
	font-size: 25px;
	font-weight: bold;
}
.fulfillment__date {
	color: #adadad;
}
.col {
	display: flex;
	flex-direction: column;
}
.text-grey {
	color: #bababa;
}
.fulfillment__details__section {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: 10px;
}
.margin-top-20 {
	margin-top: 20px;
}
.checked {
	background-color: #ffcc00;
}
.unchecked {
	background-color: #f2f2f2;
}
.order__status__name {
	margin-bottom: 25px;
	font-weight: bold;
}
.order__status__radio {
	border-radius: 50%;
	width: 30px;
	height: 30px;
	margin-bottom: 15px;
	justify-content: center;
	align-items: center;
	display: flex;
}
.order__status__names {
	display: flex;
	flex-direction: column;
	align-items: start;
	padding-top: 15px;
	// padding-bottom: 15px;
}
.status__check__fields {
	margin-right: 10px;
	padding-left: 5px;
	padding-right: 5px;
	padding-top: 10px;
	background-color: white;
	border-radius: 20px;
	display: flex;
	flex-direction: column;
	justify-items: center;
	align-items: start;
	height: 100%;
}
.order__status__content__container {
	margin-top: 18px;
	margin-bottom: 18px;
}
.order__status__fullfillment__details__text {
	color: #bababa;
	font-size: 14px;
	font-weight: 800;
	margin-top: 5px;
	text-decoration: underline;
}
.row__start {
	display: flex;
	justify-content: start;
	width: 100%;
}
.order__status__container_horizontal__line {
	background-color: #7d777717;
	width: 100%;
	height: 1px;
}
.order__status_title {
	color: black;
	font-weight: bold;
	font-size: 21px;
	margin-bottom: 18px;
}
.order__status__container {
	background-color: #e3e3e3;
	border-radius: 40px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-items: center;
	padding: 20px;
}
.order__item__container {
	border-radius: 30px;
	background-color: #e3e3e3;
	display: flex;
	margin-bottom: 20px;
	padding: 10px;
}
.yellow__image__container {
	border-radius: 30px;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 14px;
	margin: 5px;
}
.item__image {
	width: 86px;
	height: 82px;
	// background-color: red;
}
.item__description__column {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: start;
	padding-top: 5px;
	padding-bottom: 5px;
}
.description__row {
	display: flex;
	flex-direction: row;
	align-items: center;
}
.description__bullet {
	border-radius: 50%;
	background-color: #898989;
	width: 5px;
	height: 5px;
	margin-right: 5px;
}
.statuses {
	text-decoration: underline;
	text-decoration-color: black;
	text-decoration-thickness: 2px;
	margin-right: 15px;
}
h3 {
	margin-bottom: 40px;
}
.order-text {
	font-weight: bold;
}
.card-top-rightside {
	float: right;
	margin-top: -40px;
}
.order-v {
	font-size: 11px;
	padding: 5px 10px;
	text-align: center;
	background-color: #ffca05;
	border: none;
	height: auto;
	border-radius: 10px;
}
.card-top {
	padding: 10px;
}
.right-arrow {
	margin-top: -37px;
	float: right;
}
.customer-momo-card-top-left {
  max-width: 122px;
  width: 100px;
	border-radius: 30px;
	height: 100px;
	background-color: #f5f5f5;
	float: left;
}
// .customer-momo-card-top-left img {
// 	margin-top: 14px;
// 	height: 70px;
// }
.customer-momo-card-top-right {
	padding-left: 120px;
	margin-bottom: 50px;
}
.view-order-btn {
	padding: 10px;
	border: 1px solid black;
	border-radius: 15px;
	background-color: white;
	cursor: pointer;
	font-size: 12px;
	&:hover {
		background-color: #ffcc00;
    border: 1px solid #ffcc00;
	}
}
.x1-btn {
  margin-left: 10px;
  height: 20%;
  padding: 8px 12px;
  border: 2px solid #e9e9e9;
  background-color: white;
  border-radius: 10px;
  font-size: 12px;
}
.huawei-text {
	color: #898989;
}
.price-text {
	font-weight: bold;
}
.load-more-btn-holder {
	margin-top: 20px;
}
.load-more-btn {
	width: 100%;
	border: 1px solid black;
	border-radius: 20px;
	background-color: white;
	padding: 10px;
	font-weight: bold;
	cursor: pointer;
  &:hover {
		background-color: #ffcc00;
    border: 1px solid #ffcc00;
	}
}
.product-info {
	padding: 13px;
	padding-bottom: 0px;
	width: 100%;
	height: 100%;
	max-width: 400px;
	background-color: #e3e3e3;
	box-sizing: border-box;
	border-radius: 30px;
	margin: auto;
	margin-bottom: 45px;
	box-shadow: -4px 4px 28px -1px rgba(0, 0, 0, 0.19);
	-webkit-box-shadow: -4px 4px 28px -1px rgba(0, 0, 0, 0.19);
	-moz-box-shadow: -4px 4px 28px -1px rgba(0, 0, 0, 0.19);
}

.product-info > img {
	width: 200px;
	height: auto;
	margin: auto;
	display: block;
	margin-top: -20px;
}

.product-details {
	background-color: white;
	margin-left: -15px;
	margin-right: -15px;
	border-radius: 30px;
	padding: 16px;
	color: black;
}

.order__card__main {
	padding: 40px 17px;
}

.no__orders__main h3 {
	padding: 30px;
}

.no__orders__main {
	min-height: 70vh;
}

.cart__img {
	display: flex;
	margin: auto;
	margin-top: 10%;
}
.cart__img__holder button {
	padding: 10px;
	display: flex;
	margin: auto;
	width: 40%;
	margin-top: 12px;
	justify-content: center;
	border: none;
	border-radius: 20px;
	background-color: #ffcc00;
}
