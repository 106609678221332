.shareButtons_text{
    font-weight: 400;
    margin-top: 8px;
    margin-right: 14px;
}

.shareButton_iconContainer{
    margin-top: 9px;
}

.shareButton_icon{
    margin-right: 15px;
}