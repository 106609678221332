.sbeneficiary_container {
	padding: 40px 17px;
	min-height: 533px;
	background-color: white;
	.sbeneficiary__header {
		margin-top: 26px;
		color: #1c1c1c;
		font-weight: bold;
		font-size: 25px;
		margin-bottom: 4px;
	}

	p {
		font-weight: 500;
	}

	input {
		margin-top: 22px;
		width: 100%;
		border: none;
		outline: none;
		background: #f2f2f2;
		padding: 17px;
		border-radius: 12px;
		font-size: 13px;
	}

	input[type='checkbox'] {
		width: auto;
		padding: 0px !important;
	}

	input[type='radio'] {
		width: auto;
		padding: 0px !important;
	}

	input[type='file'] {
		width: auto;
	}

	input[type='range'] {
		margin-top: 0px;
		width: auto;
		border: none;
		outline: none;
		background: #f2f2f200;
		padding: 0px;
		border-radius: 0px;
		font-size: inherit;
	}

	select {
		margin-top: 22px;
		width: 100%;
		border: none;
		outline: none;
		background: #f2f2f2;
		padding: 17px;
		border-radius: 12px;
		font-size: 13px;
	}
}

.sbeneficiary_buttom {
	background-color: white;
	button {
		width: 88%;
		margin-top: 15px;
		padding: 14px;
		border-radius: 26px;
		border: none;
		background-color: #ffcc00;
		font-size: 16px;
		margin-bottom: 30px;
	}
}
