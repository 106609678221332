@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
}

body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
  font-weight: 300;
  * {
    font-family: MTNBrighterSans !important;
  }
}  

/* hide arrows on input type number
 Chrome, Safari, Edge, Opera */
 input::-webkit-outer-spin-button,
 input::-webkit-inner-spin-button {
   -webkit-appearance: none !important;
   margin: 0 !important;
 }

 ::-webkit-scrollbar {
   display: none;
}
 
 /* Firefox */
 input[type=number] {
   -moz-appearance: textfield !important;
 }

@font-face {
  font-family: "MTNBrighterSans";
  src: local("MTNBrighterSans"),
    url(./assets/fonts/MTNBrighterSans-Regular.otf) format("opentype");
}

@font-face {
  font-family: "MTNBrighterSans";
  font-weight: bold;
  src: local("MTNBrighterSans"),
    url(./assets/fonts/MTNBrighterSans-Bold.otf) format("opentype");
}

@font-face {
  font-family: "MTNBrighterSans";
  font-weight: 500;
  src: local("MTNBrighterSans"),
    url(./assets/fonts/MTNBrighterSans-Light.otf) format("opentype");
}

.centerItems{
  display: grid;
  place-items: center;
}

.customFlex{
  display: flex;
  align-items: center;
}

.columnFlex{
  flex-direction: column;
}

.justifySpaceBetween {
  justify-content: space-between;
}

.justifyCenter {
  justify-content: center;
}

.justifySpaceAround {
  justify-content: space-around;
}

.justifyEnd {
  justify-content: end;
}

.justifyStart {
  justify-content: start;
}

.justifyEvenly{
  justify-content: space-evenly;
}

.textCenter{
  text-align: center;
}

.displayNone{
  display: none;
}

.w100{
  width: 100%;
}

.h100{
  height: 100%;
}

.fBold{
  font-weight: bold;
}
