.cartCardcounter__container{
    margin-top: 10px;
    border: 1.5px solid #FFCA05;
    width: 68%;
    border-radius: 5px;
    .cartCardcounter__decrease{
        border-right: 2px solid #FFCA05;
        padding-right: 10px;
    }
    .cartCardcounter__increase{
        border-left: 2px solid #FFCA05;
        padding-left: 10px;
    }
    .cartCardcounter__number{
        margin: 0px 8px;
    }
}