.verification-title {
    color: black;
    font-size: 19px;
    margin-bottom: 15px;
  }
  
  .verification-subtitle {
    font-size: 16px;
    color: black;
    margin-bottom: 14px;
  }
  
  .verification-description {
    font-size: 12px;
    font-weight: 400;
    color: #898989;
    margin-bottom: 5px;
  }
  
  .return-button {
    margin-bottom: 40px;
  }
  
  .verification-pin-input {
    background-color: transparent;
    border: none;
    outline: none;
  }
  
  .verification-pin-input::placeholder {
    font-weight: 400;
    font-size: 11px;
    color: #797979;
  }
  
  .verification-pin-input-container {
    background-color: #f2f2f2;
    border-radius: 10px;
    padding: 10px;
  }
  
  .verification-content {
    background-color: white;
    border-radius: 20px;

  }
  

  .confirm-button {
    cursor: pointer;
    width: 80%;
    height: 5px;
    max-width: 500px;
    margin-top: 20px;
    border: none;
    font-size: 12px;
    font-weight: bold;
    border-radius: 26px;
    padding: 14px;
    background-color: #ffca05;
    border: 1px solid #ffca05;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .resend-link {
    color: black;
    text-decoration: underline;
    font-size: 12px;
  }
  
  .resend-link-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 20px;
  }