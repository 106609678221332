.modal-bg {
    background-color: rgba(0,0,0,0.5);
    position: absolute;
    top:0;
    left:0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
  }

  .modal-body {
    background-color: white;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width:80%;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 20px;
    padding-bottom: 40px;
    max-width: 400px;
  }

  .row-end { 
    display: flex;
    flex-direction: row;
    justify-content: end;
    width: 100%;
  }

  .description {
    font-size: 14px;
    color: #C4C4C4;
  }

  .title {
    font-size: 19px;
    font-weight: bold;
    margin-bottom: 12px;
    margin-top: 12px;
  }

  .continue-button {
    cursor: pointer;
    width: 100%;
    max-width: 500px;
    margin-top: 20px;
    border: none;
    font-size: 12px;
    font-weight: bold;
    border-radius: 26px;
    padding: 14px;
    background-color: #ffca05;
    border: 1px solid #ffca05;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .info-icon {
    background-color: #ffca05;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-weight: bold;
    font-size: 25px;
  }