
.choosepayment__container {
  padding: 40px 20px;
  padding-bottom: 0px;
  background-color: white;
  min-height: 500px;
  .choosepayment__header {
    color: #1c1c1c;
    font-weight: bold;
    font-size: 25px;
  }

  

  .choosepayment__subHeader{
    text-align: center;
    font-size: 14px;
    margin-top: 10px;
  }


  .choosepayment__field {
    margin-top: 34px;
    outline: none;
    border: 1px solid #c4c4c4;
    border-radius: 12px;
    cursor: pointer;
    padding: 13px;
    img{
        width: 17px;
    }
  }
  ._dropdownON {
    background: white;
    position: relative;
    display: flex;
    flex-direction: column;
    text-align: initial;
    padding: 7px 23px;
    margin-top: 20px;
    border-radius: 14px;
    box-shadow: 1px 1px 34px lightgrey;
    padding-bottom: 0px;
    p {
      padding: 9px 30px;
      padding-left: 20px;
    }
    p:hover {
      background: lightgray;
    }
  }
  ._dropdownOFF {
    display: none !important;
  }

 .choosepayment__dropdown{
    padding: 17px 0px;
    border-bottom: 1px solid #E4E4E4;
    img{
      width: 24px;
      height:24px;

      margin-right: 15px;
      object-fit: contain;
    }
 }

  input{
    font-size: 15px;
    border: none;
    outline: none;
    padding: auto;
  }

  .default_img{
    width: 24px;
    height:24px;
    border-radius: 100%;
    margin-right: 15px;
    object-fit: contain;
  }

  .payment_assurance{
    color: #797979;
    font-size: 13px;
    text-align: center;
    margin: 40px 0px;
    margin-bottom:0px ;
  }
}

.choosepayment__bottom{
  background-color: #ffffff;
  padding: 12px 16px;
  border-top-right-radius: 34px;
  border-top-left-radius: 34px;
  box-shadow: 1px 1px 24px 3px lightgrey;
  svg{
    margin-right: 8px;
  }
  div{
    color: #797979;
    font-size: 12px;
    padding: 0px 20px;
  }
  button{
    width: 100%;
    margin-top: 15px;
    padding: 12px;
    border-radius: 26px;
    border: none;
    background-color: #ffcc00;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 10px;
  }
}
