.checkbox-wrapper input[type="checkbox"] {
    /* removing default appearance */
    -webkit-appearance: none;
    appearance: none;
    /* creating a custom design */
    width: 29px;
    height: 29px;
    border-radius: 7px;
    margin-right: 20px;
    border: 0.15em solid #C4C4C4;
    outline: none;
    cursor: pointer;
  }
  .checkbox-wrapper input[type="checkbox"]:checked{
    border: none;
  }

  input.checked {
    background-color: #ffcc38;
    position: relative;
  }

  input.checked::before {
    content: "\2714";
    font-size: 1.5em;
    color: rgb(0, 0, 0);
    font-weight: bold;
    position: relative;
    left: 6px;
    top: -1px;
  }