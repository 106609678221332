$mtn-yellow: #ffcc00;
.page-title {
  display: flex;
  justify-content: start;
  width: 100%;
  margin-bottom: 10px;
}
.page-title-container {
  display: flex;
  flex-direction: column;
  justify-content: start;
  width: 100%;
}
 
.page-subtitle { font-size: 12px; margin-bottom: 5px;}
.header__container {
  padding: 9px;
  padding-bottom: 5px !important;
  background-color: $mtn-yellow;
  .header__navigation {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .header__navItems {
      display: flex;
      align-items: center;
    }
  }
  .header__search{
    margin-top: 10px;
  }
  .search_box {
    width: 95%;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: start;
    padding: 4px 2px;
    margin-bottom: 5px;
    background-color: #fff;
  }

  .search_input {
    width: 100%;
    border: none;
    outline: none;
    font-size: 14px;
    padding: 10px 14px;
    background-color: #ffcc0000;
  }

  .search_icon {
    font-size: 22px;
    margin: 4px;
    padding: 6px 15px;
  }
}


.navItem {
  text-align: center;
  padding: 0px 10px;
  cursor: pointer;

  .navItem__Icon {
    width: 17px;
    object-fit: contain;
    margin-left: 4px;
  }
  .navItem__text {
    margin: 0px !important;
    font-size: 10px;
  }

  .icon__indicator {
    background-color: #CC0040;
    width: 17px;
    height: 17px;
    border-radius: 50%;
    color: white;
    font-weight: bold;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    left: 12.5px;
    top: 7px;
  }

  .placeholder{
    background-color: transparent;
  }
}
