.footer__container{
    background-color: #000000;
    padding: 40px 12px;
    .footer__socialIcon{
        margin: 0px 7px;
    }
    .footer__navigationItem{
        color: #D7D7D7;
        font-size: 8px;
        margin: 0px 8px;
        margin-top: 20px;
    }
    .footer__credits{
        color: #7C7777;
        text-align: center;
        font-size: 9px;
        margin-top: 14px;
    }
}