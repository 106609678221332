.f_container{
    padding: 10px 26px;
    p{
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 14px;
    }
    .f_items{
        img{
            margin-right: 16px;
        }
    }
}